import React from 'react';
import Markdown from 'react-markdown';
import { graphql } from 'gatsby';
import { Layout, Section } from 'components';
import { GatsbyImage } from 'gatsby-plugin-image';

export const query = graphql`
  query PressReleasesQuery($strapiId: Int!) {
    allStrapiPressReleases(filter: { strapiId: { eq: $strapiId } }) {
      edges {
        node {
          title
          content
          images {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;

const PressRelease = ({ data }) => {
  const pressRelease = data.allStrapiPressReleases.edges;

  const _pressRelease = pressRelease.map((item, index) => {
    const _images = item.node.images.map((image, index) => {
      return (
        <div key={index}>
          <GatsbyImage
            image={image.localFile.childImageSharp.gatsbyImageData}
            alt=''
            style={{ width: '100%', maxHeight: '500px' }}
            imgStyle={{ objectFit: 'cover' }}
          />
        </div>
      );
    });
    return (
      <div key={index}>
        <div className='uk-grid'>
          <div className='uk-width-2-3@m'>
            <div className='uk-flex uk-flex-middle'>
              <div className='uk-flex uk-flex-column'>
                <div className='uk-h2 uk-text-bold'>{item.node.title}</div>
                {_images}
                <div className='uk-h5'>
                  <Markdown source={item.node.content} escapeHtml={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <Layout>
      <div className='uk-padding-large'></div>
      <Section>{_pressRelease}</Section>
    </Layout>
  );
};

export default PressRelease;
